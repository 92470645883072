import React from 'react'
import { motion } from 'framer-motion'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro' //eslint-disable-line

import useAnimatedNavToggler from '../../helpers/useAnimatedNavToggler.js'

import logo from '../../images/logo.png'
import { ReactComponent as MenuIcon } from 'feather-icons/dist/icons/menu.svg'
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg'

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`

export const NavLinks = tw.div`inline-block`

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-24 mr-3`}// Changed from w-28 to w-24
  }
`

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`)

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = 'lg',
  showNavLinks = true, // New boolean prop
  navLinks = [
    { href: '#services', text: 'Services' },
    { href: '/components/innerPages/AboutUsPage', text: 'About Us' },
    { href: '#blogs', text: 'Blog' },
    { href: '#ContactUs', text: 'Contact Us' },
    // { href: '/components/innerPages/PricingPage', text: 'Pricing' },
  ],
  getStartedLinkHref = '#ContactUs',
  getStartedLinkText = 'Get Started',
}) => {
  const {
    showNavLinks: showNavLinksProp,
    animation,
    toggleNavbar,
  } = useAnimatedNavToggler()

  const defaultLinks = [
    <NavLinks key={1}>
      {navLinks.map((link, index) => (
        <NavLink
          key={index}
          href={link.href}
          onClick={toggleNavbar} // Close mobile nav on link click
        >
          {link.text}
        </NavLink>
      ))}
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink
        css={roundedHeaderButton && tw`rounded-full`}
        href={getStartedLinkHref}
      >
        {getStartedLinkText}
      </PrimaryLink>
    </NavLinks>,
  ]

  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass]

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="logo" className={tw`mr-2`} />{' '}
      {/* Add margin-right */}
      <div className={tw`text-lg flex flex-col items-center`}>
        <div className={tw`block `}>TECHITLIVE</div>
        <div
          style={{ height: '1px', marginTop: '-12px', marginBottom: '-6px' }}
        ></div>
        <small
          className={tw`text-sm mt-1 opacity-75`}
          style={{ fontSize: '10px' }}
        >
          Empowering Digital Transformation
        </small>
      </div>
    </LogoLink>
  )

  logoLink = logoLink || defaultLogoLink
  links = links || defaultLinks

  return (
    <Header className={className || 'header-light'}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {showNavLinks && links} {/* Conditionally render links */}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {logoLink}
        <MobileNavLinks
          initial={{ x: '150%', display: 'none' }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {showNavLinks && links} {/* Conditionally render links */}
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinksProp ? 'open' : 'closed'}
        >
          {showNavLinksProp ? (
            <CloseIcon tw="w-6 h-6" />
          ) : (
            <MenuIcon tw="w-6 h-6" />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  )
}

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
}
